<template>
  <section id="new-connection">
    <b-row class="match-height">
      <b-col cols="12">
        <div>
          <b-card no-body>
            <b-overlay
              :show="showLoading"
              spinner-variant="primary"
              blur="0"
              opacity=".75"
              rounded="sm"
            >
              <b-card-header>
                <b-card-title>
                  Ссылки 404
                </b-card-title>
              </b-card-header>
              <b-card-body>
                <b-row>   <b-col md="2">
                  <b-form-group
                      label="Проверка ссылок"
                      label-for="cost-122"
                  >
                    <b-button
                        variant="outline-success"
                        @click="checkAllLinks()"
                    >
                      <feather-icon
                          icon="CheckIcon"
                          class="mr-50"
                      />
                      <span class="align-middle">Проверить ссылки</span>
                    </b-button>
                  </b-form-group>
                </b-col>
                  <b-col md="2">
                    <b-form-group
                        label="Фильтр по результату проверки"
                        label-for="cost-122"
                    >
                      <v-select
                          v-model="resultFilter"
                          :options="optionsWithProperties"
                          label="text"
                          placeholder="Выберите статус"
                      />
                    </b-form-group>
                  </b-col>
                </b-row>
                <b-table
                  :data="data"
                  :paginated="true"
                  class="mb-1 mt-1"
                >
                  <b-table-column
                    v-slot="props"
                    field="ads_link"

                    label="Ссылка"
                  >
                    {{ props.row.ads_link }}
                  </b-table-column>

                  <b-table-column
                    v-slot="props"
                    field="result"
                    label="Результат проверки"
                  >
                    <b-badge :variant="statusVariant(props.row.result)">
                      {{ statusLabel(props.row.result) }}
                    </b-badge>
                  </b-table-column>
                  <template #empty="scope">
                    <transition
                      name="fade"
                      mode="out-in"
                    >
                      <b-col
                        cols="12"
                      >
                        <div class="misc-inner p-2 p-sm-3">
                          <div class="w-100 text-center">
                            <h4 class="mb-1">
                              Нет данных
                            </h4>
                          </div>
                        </div>
                      </b-col>
                    </transition>
                  </template>
                </b-table>
              </b-card-body>
            </b-overlay>
          </b-card>
        </div>
      </b-col>
    </b-row>
  </section>
</template>
<script>

import {
  BButton,
  BCard,
  BCardBody,
  BCardHeader,
  BOverlay,
  BCardTitle,
  BCol,
  BBadge,
  BTooltip,
  BRow,
  BImg,
  BTabs,
  BTab,
  BForm,
  BTableLite,
  BFormSelect,
  BFormRadioGroup,
  BFormCheckboxGroup,
  BFormGroup,
  BFormCheckbox,
  VBToggle, VBTooltip,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import OpenIndicator from '@core/components/vue-select/OpenIndicator.vue'
import flatPickr from 'vue-flatpickr-component'
import ToastificationContent from '@core/components/toastification/ToastificationContent'
import KeysRecommendationDashboard from '@/views/recommendations/KeysRecommendationDashboard.vue'

const dateNow = new Date()
const dateNowPrev = new Date(new Date().setMonth(new Date().getMonth() - 1))

vSelect.props.components.default = () => ({ OpenIndicator })

export default {
  components: {
    KeysRecommendationDashboard,
    BCard,
    BButton,
    BFormCheckbox,
    vSelect,
    BForm,
    BImg,
    BTooltip,
    BTabs,
    BTab,
    BFormSelect,
    BCardHeader,
    BTableLite,
    BCardBody,
    BOverlay,
    BCardTitle,
    BRow,
    BBadge,
    BCol,
    BFormCheckboxGroup,
    BFormGroup,
    BFormRadioGroup,
    flatPickr,
  },
  directives: { 'b-toggle': VBToggle, 'b-tooltip': VBTooltip, Ripple },
  props: {
  },
  data() {
    return {
      data: [],
      adsLinksError: [],
      resultFilter: null,
      optionsWithProperties: [
        {
          value: 'Success',
          text: 'Успешная проверка',
        },
        {
          value: 'Error',
          text: 'Ошибка 404',
        },
        {
          value: 'HostError',
          text: 'Ошибка хоста',
        },
        {
          value: 'All',
          text: 'Все',
        },
      ],
      columnsVisible: {
        ads_link: { title: 'Ссылка', display: true },
        result: { title: 'Результат проверки', display: true },
      },
      errorLinksTableLoading: false,
      perPage: {
        value: 30,
        text: '30 на странице',
      },
      perEffectPage: {
        value: 30,
        text: '30 на странице',
      },
      showLoading: false,
      saveData: [],
    }
  },
  computed: {
    activeProjectId() {
      return this.$store.state.project.activeProjectId
    },
    statusVariant() {
      const statusColor = {
        /* eslint-disable key-spacing */
        Success : 'light-success',
        Error : 'light-danger',
        HostError: 'light-danger',
      }

      return status => statusColor[status]
    },
    statusLabel() {
      const statusLabel = {
        /* eslint-disable key-spacing */
        Success : 'Успешная проверка',
        Error : 'Ошибка 404',
        HostError: 'Ошибка хоста',
      }

      return status => statusLabel[status]
    },
  },
  watch: {
    activeProjectId(oldState, newState) {
      this.errorLinksTableLoading = true
      this.data = []
      this.loadCheckAds(oldState)
    },
    resultFilter(newState, oldState) {
      if (newState != null) {
        // eslint-disable-next-line default-case
        switch (newState.value) {
          case 'All': this.data = JSON.parse(JSON.stringify(this.saveData))
            break
          case 'Error': this.data = JSON.parse(JSON.stringify(this.saveData))
          // eslint-disable-next-line eqeqeq
            .filter(stat => stat.result === 'Error')
            break
          case 'Success': this.data = JSON.parse(JSON.stringify(this.saveData))
            .filter(stat => stat.result === 'Success')
            break
          case 'HostError': this.data = JSON.parse(JSON.stringify(this.saveData))
              .filter(stat => stat.result === 'HostError')
            break
        }
      }
    },
  },
  mounted() {
    this.errorLinksTableLoading = true
    if (this.activeProjectId) {
      this.loadCheckAds(this.activeProjectId)
    }
  },
  created() {
  },
  methods: {
    checkAllLinks() {
      this.$bvModal
        .msgBoxConfirm('Вы уверены, что хотите начать проверку? Это может занять время', {
          title: 'Проверка ссылок на 404',
          size: 'sm',
          okVariant: 'primary',
          okTitle: 'Да, уверен',
          cancelTitle: 'Нет',
          cancelVariant: 'outline-secondary',
          hideHeaderClose: false,
          centered: true,
        })
        .then(value => {
          this.showLoading = true
          if (value) {
            this.$http.get(`/yandex-direct/check-notfound?projectId=${this.activeProjectId}`)
            // eslint-disable-next-line no-unused-vars
              .then(response => {
                // eslint-disable-next-line
                    this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: 'Проверка ссылок успешно завершена',
                    icon: 'SettingsIcon',
                    variant: 'success',
                  },
                })
                this.showLoading = false
              })
          } else {
            this.showLoading = false
          }
        })
    },
    loadCheckAds(projectId) {
      this.$http.get(`/yandex-direct/getCheckAds?projectId=${projectId}`)
        .then(adsResponse => {
          const dataForParse = adsResponse.data
          const dataForTable = []
          Object.keys(dataForParse)
            .forEach(it => {
              const itemKey = dataForParse[it]
              dataForTable.push({
                ads_link: itemKey.url,
                result: itemKey.status,
              })
            })
          this.data = dataForTable
          this.saveData = dataForTable
          this.errorLinksTableLoading = false
        })
    },
  },
}
</script>

<style scoped>

.no-content {
  margin:0 auto;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';
@import '@core/scss/vue/libs/vue-select.scss';
</style>
